import React, { useEffect, useState, useRef, useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { Tab, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BigNumber, ethers } from 'ethers'
import contractABI from './ABI/contractABI.json'

const contractAddress = '0xcd45A6C47c76029b8c15866F867884a6494D657A';

const TokenList = () => {

    const {
        language,
        defaultAccount,
    } = useContext(ThemeContext);


    //連接錢包
    const [provider, setProvider] = useState(null)
    const [signer, setSigner] = useState(null)
    //各模板
    const [contract, setContract] = useState(null)
    const [tokenList, setTokenList] = useState([]);

    const [currentData, setCurrentData] = useState([]);

    const updateEthers = async () => {
        let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(tempProvider);

        let tempSigner = tempProvider.getSigner();
        setSigner(tempSigner);

        let tempContract = new ethers.Contract(contractAddress, contractABI, tempSigner)
        setContract(tempContract);

        let tempTokenList = await tempContract.getContractList(defaultAccount);
        setTokenList(tempTokenList);
    }

    useEffect(() => {
        updateEthers();
    }, [defaultAccount])

    //翻頁設計
    const pageSize = 3;
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate the start and end index of the current page
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    useEffect(() => {
        if (tokenList != [])
            setCurrentData(tokenList.slice(startIndex, endIndex))
    }, [startIndex, tokenList])

    // Handle the click event for the previous button
    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
        else {
            alert("没有更多页了")
        }
    };

    // Handle the click event for the next button
    const handleNextClick = () => {
        if (endIndex < tokenList.length) {
            setCurrentPage(currentPage + 1);
        }
        else {
            alert("没有更多页了")
        }
    };

    const handleDate = (timeValue) => {
        const data = new Date(timeValue * 1000);
        const dateString = data.toLocaleString();
        return dateString;
    }

    return (
        <>
            <div className="row">
                <h1> My Token List </h1>
                <div className="col-12">
                    <div className="card">
                        <Tab.Container defaultActiveKey="All">
                            <div className="card-header border-0 pb-2 flex-wrap">
                                <h4 className="heading">My Token List - {tokenList.length}</h4>
                                <>
                                    {/* <Nav className="order nav nav-tabs">
                                        <Nav.Link as="button" eventKey="All" type="button">Order</Nav.Link>
                                        <Nav.Link as="button" eventKey="Order" type="button">Order History</Nav.Link>
                                        <Nav.Link as="button" eventKey="Trade" type="button">Trade Histroy</Nav.Link>
                                    </Nav> */}
                                    {/* <Nav className="order nav nav-tabs">
                                        <Nav.Link as="button" eventKey="All" type="button">5 Datas</Nav.Link>
                                        <Nav.Link as="button" eventKey="Show All" type="button">Show All</Nav.Link>
                                        <Nav.Link as="button" eventKey="Trade" type="button">Trade Histroy</Nav.Link>
                                    </Nav> */}
                                </>
                            </div>
                            <div className="card-body pt-0 pb-0">
                                <Tab.Content >
                                    <Tab.Pane eventKey="All">
                                        <div className="table-responsive dataTabletrade ">
                                            <div id="future_wrapper" className="dataTables_wrapper no-footer">
                                                {/* 代幣列表 */}
                                                <table id="example" className="table display dataTable no-footer" style={{ minWidth: "845px" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>前往控制台</th>
                                                            <th>No.</th>
                                                            <th>Address</th>
                                                            <th>Module</th>
                                                            <th>Name</th>
                                                            <th>Symbol</th>
                                                            <th>Supply</th>
                                                            <th>Decimal</th>
                                                            <th className="text-end">Creation Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            currentData != [] &&
                                                            currentData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/control-panel`,
                                                                            search: `?contract=${item[0]}`
                                                                        }}>
                                                                        <td>
                                                                            <Button className="me-2" variant="primary">
                                                                                <span style={{ textAlign: "center" }}>→</span>
                                                                            </Button>
                                                                        </td>
                                                                    </Link>
                                                                    <td>{startIndex + index + 1}</td>
                                                                    <td>{item[0]}</td>
                                                                    <td>{item[1].toNumber()}</td>
                                                                    <td>{item[2]}</td>
                                                                    <td>{item[3]}</td>
                                                                    <td>{item[4].toNumber().toString()}</td>
                                                                    <td>{item[5].toNumber()}</td>
                                                                    <td className="text-end"> {handleDate(item[6].toNumber())}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {/* 代幣列表 */}
                                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                                    <div className="dataTables_info">
                                                        <span>Showing {startIndex + 1} to {endIndex > tokenList.length ? tokenList.length : endIndex} of {tokenList.length} contracts</span>
                                                    </div>
                                                    <div
                                                        className="dataTables_paginate paging_simple_numbers mb-0"
                                                        id="application-tbl1_paginate"
                                                    >
                                                        <span
                                                            className="paginate_button previous"
                                                            onClick={handlePrevClick}
                                                        >
                                                            <i className="fa fa-angle-double-left" ></i>
                                                        </span>
                                                        <span className="paginate_button current primary" style={{ fontSize: '18px', padding: '0px 10px' }}>
                                                            {currentPage}
                                                        </span>
                                                        <span
                                                            className="paginate_button next"
                                                            onClick={handleNextClick}
                                                        >
                                                            <i className="fa fa-angle-double-right" ></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="Order">
                                        <OrderTab />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Trade">
                                        <TradeTab />
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export const ModuleList = (value) => {
    let word;
    switch (value) {
        case 0:
            word = "0稅"
            break;
        case 1:
            word = "營銷回流"
            break;
        default:
            word = "其他"
    }
    return word;
}
export default TokenList;